$yellow_color: #FABE47;
$blue_color: #575FCC;
$black_color: #4F4E4E;
$grey_color: #434343;

$grey_light:#E5E5E5;
$grey_background: #F8F8F8;

$beige_background: #FFF8F3;

$header_height: 3rem;
