@import "../../constants";
.main {
  padding:1.75rem;

  .graphs {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    #pie {
      width: 35% !important;
      height: 35% !important;
      padding-bottom: 0.5rem;
    }
    #bar {
      height: 35% !important;
      width: 60% !important;
    }

    .slot-selector {
      height: 0.25rem;
      width: 1rem;
      position: absolute;
      bottom: 0;
      background-color: $blue_color;
    }
  }

  .data {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    color: white;

    p {
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
    }

    .remarked {
      padding: 0.5rem;
      width: 35%;
    }

    .selected {
      padding: 0.5rem;
      width: 55%;
    }
  }

  .reactions {
    max-height: 48vh ;
    overflow-x: scroll;
  }

  .box {
    //border-radius: 12px;
    background: $blue_color;
//    opacity: 0.9;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10));

  }
}

.title {

}
@media only screen
and (min-width: 320px)
and (max-width: 812px)
  // and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .main {
    padding: 1rem;
  }
  .reactions {
    max-height: 54vh ;
  }
}
