@import 'src/constants';

.header {
  background: $blue_color;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;

  position: sticky;
  top: 0;
  height: $header_height;

  &-icon {
    svg {
      height: 3rem;
    }
  }
}
