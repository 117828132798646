@import './constants';

.app {
  background: $beige_background;
  height: 100vh;

  .emissions {
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    background: #FFF8F3;
  }

  .emission {
    width: 100%;
    padding: 0.75rem;
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    //border-radius: 12px;
    cursor: pointer;

    &-selected {
      background: $grey-light;
    }
    &-header {
      display: flex;
      gap: 0.75rem;
      align-items: center;

      .time {
        color: grey;
        font-size: small;
      }

      h3 {
        margin: 0;
      }

    }

    &-subtitle {
      font-size: smaller;
    }
  }
}

.channel-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.75rem;
  justify-content: center;
  gap:1em;

  img {
    width: 92px

  }

}

.search {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$header_height} - 2*0.75rem);
  overflow: hidden;
  .toolbar {
     min-width: 16%;
  }
  .emissions {
    height: 90%;
    overflow-y: scroll;
  }
  .analytics {
    min-width: 50%;
    height: 90%;
    background: $beige_background;

  }
}

.toolbar {
  padding: 1.5rem;

  .filter-group {
    background: white;

    padding: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10));

    label {
      font-weight: bold;
    }

    .input {
      background: $grey_background;
      border: none;
      padding: 0.5rem;
      font-size: 1rem;
      font-style: normal;
    }
    .button {
      background: unset;
      border: unset;
    }
  }

  .filter-btn {
    background: $blue_color;
    color: white;
    font-weight: bold;
    text-align: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10));
    font-size: 1rem;
    width: 100%;
    padding: 0.75em;
    border: unset;

  }
}
.analytics {
  background: white;
  padding-top: 1.75em;
  .buttons {
    display: flex;
    justify-content: space-between;
    padding-right: 0.75em;
    padding-left: 1.75em;
    button {
      background-color: transparent;
      border: unset;
      padding: 0.75em;
      font-size: large;
      font-weight: bold;
      color: $blue_color;
      cursor: pointer;
    }
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

@media only screen
and (min-width: 320px)
and (max-width: 812px)
// and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .search {
    display: block;
    .emissions {
      height: 50%;
    }
  }
  .analytics {
   position: absolute;
    min-width: inherit;
    width: 100%;
    top:  calc(#{$header_height} + 2*0.75rem);
    height: calc(100vh - #{$header_height} - 2*0.75rem);
  }
}


