@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Lato:wght@100;300;400;700;900&family=Poppins:ital,wght@0,400;0,700;1,400&display=swap');

body {
  height: 100vh;
  margin: 0;
  font-family:'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

