@import "../../constants";

.reaction {
  position: relative;
  margin: 0.5rem;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;

  background: white;
  padding: 0.5rem 1rem;

  color: black;
  text-align: left;

  .username {
    font-size: small;
    font-weight: bold;
    word-break: break-all;

  }

  .message {
   p {
     font-size: small;
     font-weight: normal;
      text-align: left;

   }
    box-sizing: border-box;
    max-height: 50px; // only four lines
    overflow: hidden;

    &:after {
      content: "\02026";
      position: absolute;
      top: 25px;
      right: 0.25em;
      width: 3em;
     // background: linear-gradient(to right, rgba(255, 255, 255, 0), $grey_background 50%, $grey_background);
      text-align: right;
      vertical-align: bottom;
      font-size: medium;
      font-weight: bold;

    }

    &-big-text {
      max-height: unset !important;
    }

  }

  .likes {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 0.5rem;
    color: #575FCC;

    .blue-circle {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 23px;
      height: 23px;
      background: $blue-color;
      border-radius: 50%;
    }
  }
}

